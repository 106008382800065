import React from 'react';
import * as Styled from './styles';
import { Link } from 'gatsby';

const Title: React.FC = () => {
  return (
      <Styled.Text>
        CU <br></br>Home Hub
      </Styled.Text>
  );
};

export default Title;
