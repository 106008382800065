import React from 'react';

import Container from 'components/ui/Container';
import { Link } from "gatsby"

import * as Styled from './styles';

const Footer: React.FC = () => (
  <Styled.Footer>
    <Container>
      <Styled.Links>
        <Styled.Link href="https://www.instagram.com/lively.ie/" target="_blank">
            Instagram
        </Styled.Link>
        -
        <Styled.Link href="mailto:info@cuhomehub.ie" target="_blank">
          info@cuhomehub.ie
        </Styled.Link>
        - 
        Created by Homebuying Software Ltd.
      </Styled.Links>
    </Container>
  </Styled.Footer>
);

export default Footer;
