import { createGlobalStyle } from 'styled-components';
import tw from 'tailwind.macro';

export default createGlobalStyle`
body {
  ${tw`m-0 bg-white`};
  color: #5ac14b;
}

a {
  color: #5ac14b; /* Darker green */
}

a:hover {
  color: #87db70; /* Lighter green for hover */
}

  p + p {
    ${tw`mt-3`};
  }

  ul {
    ${tw`list-disc m-4`};
  }

  h3 {
    ${tw`pt-5 pb-1 text-lg`};
  }
`;
