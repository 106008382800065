import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Footer = styled.footer`
  ${tw`border-t border-gray-200 py-4`};
`;

export const Links = styled.div`
  ${tw`flex items-center justify-center w-full`};
`;

export const Link = styled.a`
  ${tw`mx-2 font-bold`};
  border-bottom: 2px solid transparent; /* Initially transparent bottom border */

  &:hover {
    border-bottom-color: #5CE1E6; /* Light blue bottom border color on hover */
  }
`;