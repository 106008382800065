import styled from 'styled-components';
import tw from 'tailwind.macro';
import { Container } from 'components/ui/Container/styles';

export const Header = styled.header`
${tw`border-b border-gray-200 -mb-px`};
background-color: #FFFFB0;
`;

export const Wrapper = styled(Container)`
  ${tw`items-center`};
`;
